<template>

<div class="card m-3" style="width: 18rem;">
  <div class="card-img-top d-flex justify-content-end">
    <img @click="setEditPrinter" data-bs-toggle="modal" data-bs-target="#printerModal" height="220px" :src="printer.fileImageAddress"/>
    <!-- <div class="loadingio-spinner-ripple-2by998twmg9"><div class="ldio-yzaezf3dcml"> -->
    <div class="dropdown options">
      <button class="btn optionsButton" type="button" id="dropdownMenuButton1" data-bs-toggle="dropdown" aria-expanded="false">
        <i class="bi bi-three-dots"></i>
      </button>
      <ul class="dropdown-menu" aria-labelledby="dropdownMenuButton1">
        <li
        v-b-tooltip.hover
        :title="''"
        >

        <button
          :disabled="false"
          @click="removeTicket" data-bs-toggle="modal"
          data-bs-target="#removeTicket" class="dropdown-item"
         
          >{{ manageStore.lang("cards.modelcard.options.delete_ticket") }}</button>
          
        </li>
      </ul>
</div>
</div>
  <div class="card-body bg-primary3">
       <h4 style="font-weight: bold;" class="card-title text-white">{{ printer.name }}</h4>
       <div style="width: 10rem; height: 2.5rem;" class="d-flex mt-4 gap-2 p-2 bg-primary rounded">
          <p class="text-white">Color in use</p>
          <div class="rounded" :style="'width: 1.5rem; height: 1.5rem; background-color:' + ticketStore.convertMaterialToColorUI(printer.colorInUse) + ';'"></div>
       </div>
  </div>
</div>

</template>

<script>

import  {Printer} from "../../other/DataStructs.ts"
import { useTicketsStore } from '@/stores/tickets.ts';
import { useManageStore } from '@/stores/manage';
import { usePrinterStore } from "@/stores/printers.ts";

export default {
  name: 'PrinterCard',

  props:{
    printer: Printer,
  },

  setup(props){
    const ticketStore = useTicketsStore();
    const manageStore = useManageStore();
    const printerStore = usePrinterStore();

    function setEditPrinter(){
      printerStore.editPrinter = props.printer;
      printerStore.setEditTicketQueue(props.printer);
    }

    return{
        ticketStore,
        manageStore,
        printerStore,
        setEditPrinter
    }
  }
}

</script>

<style scoped>

.card-img-top, .card-title{
  cursor: pointer;
}

.optionsButton{
  cursor: pointer;
  background-color: rgba(255, 255, 255);
  opacity: 60%;

}
.optionsButton:hover{
  opacity: 95%;
}

.iconbutton{
  cursor: pointer;
}

.btn.disabled {
    pointer-events: auto;
}

</style>
import { defineStore} from "pinia";
import {Printer, PrintingTicket} from '../other/DataStructs'
import { useTicketsStore } from "./tickets";
import { ref} from "vue";

export const usePrinterStore = defineStore("printerStore", {
    state: () =>{
        return {
            //tickets
            printers: ref(
                [
                    new Printer("Prusa Printer 001", "http://www.printedsolid.com/cdn/shop/files/5508.jpg?v=1686947516", "white"),
                    new Printer("Ultimaker mk1 001", "https://imade3d.com.cy/wp-content/uploads/2020/11/Ultimaker-S5-Studio_23-scaled-1.jpg", "red"),
                    new Printer("Ultimaker mk1 002", "https://imade3d.com.cy/wp-content/uploads/2020/11/Ultimaker-S5-Studio_23-scaled-1.jpg", "black"),
                    new Printer("Prusa Printer 002", "http://www.printedsolid.com/cdn/shop/files/5508.jpg?v=1686947516", "orange"),
                    new Printer("Sketch Large 001", "https://www.makerbot.com/wp-content/uploads/2023/09/2019-11-21-SKETCH-SINGLE-PRINTER-HERO-10.png", "blue"),
                ] as Printer[]
            ),
            editPrinter: new Printer("", "", ""),
            editTicketQueue: [] as any[]
        };
    },
    actions: {
        setEditTicketQueue(printer: Printer){
            const ticketStore = useTicketsStore();
            this.editTicketQueue.length = 0;

            for (let i = 0; i < printer.ticketQueue.length; i++) {
                const id = printer.ticketQueue[i];
                this.editTicketQueue.push(ticketStore.getTicket(id));
            }
        }
    },

});
<template>
    <nav-bar :show="ticketStore.showNavbar"></nav-bar>
    <router-view v-slot="{ Component }">
      <transition name="route" mode="out-in">
        <component :is="Component" />
      </transition>
    </router-view>

    <!--Dialogs-->

    <ModalFrame modalID="uploadModal" :modalTitle="manageStore.lang('dialogs.uploadModal.title')" modalSize="lg">
        <UploadModal></UploadModal>
    </ModalFrame>
    
    <ModalFrame modalID="TicketEditModal" :modalTitle="manageStore.lang('dialogs.editTicketModal.title')" modalSize="lg">
      <EditTicketModal></EditTicketModal>
    </ModalFrame>

    <ModalFrame modalID="profileModal" :modalTitle="manageStore.lang('dialogs.editProfile.title')" modalSize="lg">
      <ProfileModal></ProfileModal>
    </ModalFrame>

    <ModalFrame modalID="assigneeModal" :modalTitle="manageStore.lang('dialogs.assigneeModal.title')" modalSize="lg">
      <AssigneeModal></AssigneeModal>
    </ModalFrame>

    <ModalFrame modalID="printerModal" :modalTitle="manageStore.lang('dialogs.printerModal.title')" modalSize="lg">
      <PrinterModal></PrinterModal>
    </ModalFrame>

    <ModalFrame modalID="setPrintedModal" :modalTitle="manageStore.lang('dialogs.assigneeModal.title')" modalSize="sm">
      <setPrintedModal></setPrintedModal>
    </ModalFrame>

    <ModalFrame modalID="removeTicket" :modalTitle="manageStore.lang('dialogs.removeTicketModal.title')" modalSize="sm">
      <removeTicketModal></removeTicketModal>
    </ModalFrame>

    <p class="copyright fst-italic">© Software developed in the WM-AUT Department</p>
</template>

<script>

import NavBar from "@/components/NavBar.vue";
import ModalFrame from './dialogs/ModalFrame.vue';
import UploadModal from './dialogs/UploadModal.vue';
import EditTicketModal from "./dialogs/EditTicketModal.vue";
import ProfileModal from './dialogs/ProfileModal.vue';
import AssigneeModal from '@/components/dialogs/AssigneeModal.vue';
import PrinterModal from "./dialogs/PrinterModal.vue";
import setPrintedModal from "./dialogs/setPrintedModal.vue";
import removeTicketModal from "@/components/dialogs/removeTicketModal.vue";

import { useTicketsStore } from "@/stores/tickets";
import { useManageStore } from "@/stores/manage";
import '@/components/ThreeScene/3dloader';
// import * as bootstrap from 'bootstrap';
// window.Modal = bootstrap.Modal;

export default {
  name: 'MainView',
  components: {
    NavBar,
    ModalFrame,
    UploadModal,
    EditTicketModal,
    ProfileModal,
    AssigneeModal,
    PrinterModal,
    setPrintedModal,
    removeTicketModal
  },
  setup(){

    const ticketStore = useTicketsStore();
    const manageStore = useManageStore();

    return{
      manageStore,
      ticketStore,
    }
  }
}

</script>

<style scoped>

#threeviewport{
  position: fixed;
  z-index: 10000;
  top: 0px;
  left: 50%;
  width: 260px;/* only initial! */
  height: 140px;
}

.copyright{
  position: fixed;
  bottom: -5px;
  right: 10px;
}

/* route transition */

.route-enter-from{
  opacity: 0;
  transform: translateX(100px);
}
.route-enter-active{
  transition: all 0.3s ease-out;
}
.route-leave-to{
  opacity: 0;
  transform: translateX(-100px);
}
.route-leave-active{
  transition: all 0.3s ease-in;
}
</style>
<template>  
    <!-- Modal -->
    <modal class="modal fade" :id="modalID" tabindex="-1" :aria-labelledby="modalID+'Label'" aria-hidden="true" show.bs.modal="true">
      <div :class="'modal-dialog modal-'+modalSize">
        <div class="modal-content">
          <div class="modal-header bg-primary2 shadow-sm">
            <h1 class="modal-title fs-5 text-white" :id="modalID+'Label'">{{ modalTitle }}</h1>
            <i class="bi bi-x-square ms-auto text-white closebtn" data-bs-dismiss="modal" aria-label="Close"></i>
          </div>
          <div class="modal-body">
            <slot></slot>
          </div>
        </div>
      </div>
    </modal>
    
    </template>
    
<script>
    export default {
      name: 'ModalFrame',
      props:{
        modalID: String,
        modalTitle: String,
        modalSize: String,
      },
    }
    </script>
    
<style scoped>

.modal{
  position: fixed;
  left: 0px;
  top: 0px;
}
.closebtn{
  cursor: pointer;
  scale: 200%;
  opacity: 60%;
}
.closebtn:hover{
  opacity: 100%;
}

</style>
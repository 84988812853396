import { createRouter, createMemoryHistory } from 'vue-router'
import TicketView from '../components/sites/TicketView.vue'
import LoginView from '../components/sites/LoginView.vue'
import StatsView from '../components/sites/StatsView.vue'
import PrinterView from '../components/sites/PrinterView.vue'
import AuthFailed from '@/components/sites/AuthFailed.vue'

const routes = [
  {
    path: '/',
    component: TicketView,
  },
  {
    path: '/sign_in',
    component: LoginView,
  },
  {
    path: '/stats',
    component: StatsView,
  },
  {
    path: '/printer',
    component: PrinterView,
  },
  {
    path: '/auth_failed',
    component: AuthFailed,
  },
]

const router = createRouter({
  history: createMemoryHistory(),
  routes,
})

export default router;
<template>

<div>
    <p>Is the model finished printing?</p>
</div>

<div class="d-flex justify-content-center gap-2">
    <button @click="setPrinted(2)" data-bs-dismiss="modal" type="button" class="btn btn-primary">yes</button>
    <button @click="setPrinted(1)" data-bs-dismiss="modal" type="button" class="btn btn-primary">no</button>
</div>

</template>

<script>
import { useTicketsStore } from '@/stores/tickets';
import { useManageStore } from '@/stores/manage';

export default {
    name: "setPrintedModal",
    setup(){
        const ticketStore = useTicketsStore();
        const manageStore = useManageStore();

        function setPrinted(status){
            const ticket = ticketStore.getTicket(ticketStore.assigningTicket);
            ticket.status = status;


            if(status == 2){
                manageStore.sendNotification("printed", ticket.client, ticket.assignee, ticket.ticket_name);
            }

            ticketStore.putTicket(ticket);
        }

        return {
            setPrinted,
            manageStore,
            ticketStore
        }
    }
}

</script>

<style>

</style>
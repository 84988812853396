<template>

<div>
    <p>{{ manageStore.lang("dialogs.removeTicketModal.content") }}</p>
</div>

<div class="d-flex justify-content-center gap-2">
    <button @click="removingTicket" data-bs-dismiss="modal" type="button" class="btn btn-primary">yes</button>
    <button data-bs-dismiss="modal" type="button" class="btn btn-primary">no</button>
</div>

</template>

<script>
import { useTicketsStore } from '@/stores/tickets';
import { useManageStore } from '@/stores/manage';

export default {
    name: "removeTicketModal",
    setup(){
        const ticketStore = useTicketsStore();
        const manageStore = useManageStore();

        function removingTicket(){
            ticketStore.removeTicket(ticketStore.editTicket);
        }

        return {
            removingTicket,
            manageStore,
            ticketStore
        }
    }
}

</script>

<style>

</style>
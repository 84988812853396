import '../css/custom.css'
import { FunctionPlugin, createApp} from 'vue'
import { createPinia} from "pinia"
import App from './App.vue'
import 'bootstrap/dist/js/bootstrap.min.js'
import { BootstrapVue } from 'bootstrap-vue'
import 'bootstrap-vue/dist/bootstrap-vue.css'
import router from './other/router';
import Keycloak from 'keycloak-js';
import { useAuthStore } from './stores/authStore'
import { useTicketsStore } from './stores/tickets'
import { useManageStore } from './stores/manage'

const keycloak:any = new Keycloak(
    {
        url: 'https://keycloak.wmaut.de',
        realm: 'application',
        clientId: 'printer-system',
    }
);
let authStore:any = null;

keycloak.init({
    onLoad: "login-required"
}).then((auth:boolean) =>{
    if(!auth){
        window.location.reload();
    }
    else{
        console.log("Authenticated");

        const app = createApp(App)
        app.use(createPinia());
        app.use(BootstrapVue as unknown as FunctionPlugin);//dangerous avoiding typescript!
        app.use(router).mount('#app')

        keycloak.updateToken(70);
        if(useAuthStore() != null){
            authStore = useAuthStore();
            authStore.keycloak = keycloak;
            authStore.decodeJWT(keycloak.token);
        }

        if(useTicketsStore() != null){
            const ticketStore = useTicketsStore();
            ticketStore.fetchAllTickets();
        }
    }

    setInterval(() => {
        if(useAuthStore() != null){
            authStore = useAuthStore();
        }
        
        keycloak.updateToken(70).then((refreshed: boolean) => {
            if(refreshed){
                console.log('Token refreshed' + refreshed);
            }
            else{
                console.log('Token not refreshed, valid for '
                    + Math.round(keycloak.tokenParsed.exp + keycloak.timeSkew - new Date().getTime() / 1000) + 'seconds'
                );
            }
        })
        if(authStore != null){
            authStore.decodeJWT(keycloak.token);
        }
    }, 6000)

})
.catch((e:any) => {
    console.log("Authentication Failed:", e);
    const manageStore = useManageStore();
    manageStore.errorCode = e;
    router.push("auth_failed");
})
<template>
    <div class="d-flex flex-column justify-content-center align-items-center">
        <img class="mt-4" width="500px" src="../../assets/images/wzl_navbar_logo.png" alt="WZL logo">
        <button class="btn mt-4 border" style="width: 500px;">WM_AUT SSO</button>
    </div>
</template>

<script lang="ts">

import { useManageStore } from '@/stores/manage';

export default {
    name: "LoginView",
    setup(){
        const manageStore = useManageStore();
        manageStore.showNavbar = false;

        return{
            manageStore
        }
    }
}

</script>

<style scoped>

</style>
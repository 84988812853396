<template>

<div class="d-flex flex-column align-items-center w-100 h-100">
    <div class="" style="width: 40%;">
    <b-row>
        <b-col>
            <img class="rounded-circle" width="200px" :src="manageStore.baseUserImageUrl + authStore.preferred_username" alt="user image">
        </b-col>
    </b-row>

    <b-row class="mt-4">
        <b-col class="d-flex flex-column rounded">
            <label class="fs-5">User Name</label>
            <div class="border rounded disabledForm">
                <p class="text-start m-2">{{ authStore.given_name + " " + authStore.family_name }}</p>
            </div>
        </b-col>
    </b-row>

    <b-row class="mt-4">
        <b-col>
            <label class="fs-5">User Role</label>
            <div class="border rounded disabledForm">
                <p class="text-start m-2">{{ manageStore.userRole }}</p>
            </div>
        </b-col>
    </b-row>
</div>

</div>


</template>

<script>

import { useManageStore } from '@/stores/manage';
import { useAuthStore } from '@/stores/authStore';

export default {
    name: "ProfileModal",
    setup(){
        const manageStore = useManageStore();
        const authStore = useAuthStore();
        const imagePrefix = "https://chat.wmaut.de/avatar/";

        function changeUserImage(){
            manageStore.userImage = imagePrefix+authStore.preferred_username;
        }
        
        return{
            manageStore,
            changeUserImage,
            authStore
        }
    }
}

</script>

<style scoped>

</style>
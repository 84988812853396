<template>
    <div v-if="printerStore.editPrinter != null" class="mx-4 my-3">
        <div class="row">
            <div class="col">
                <img style="width: 14rem;" :src="printerStore.editPrinter.fileImageAddress" alt="">
                <h2>{{ printerStore.editPrinter.name }}</h2>
                <div class="p-3 border rounded">
                    <div class="mt-4">
                        <p class="text-start">color in use</p>
                        <div style="width: 6rem;" class="border rounded disabledForm d-flex align-items-center">
                            <p class="text-start m-2">{{ printerStore.editPrinter.colorInUse }}</p>
                            <div :style="'width: 1.5rem; height: 1.5rem; background-color: ' + ticketStore.convertMaterialToColorUI(printerStore.editPrinter.colorInUse) " class="rounded"></div>
                        </div>
                    </div>
                    <div class="mt-4">
                        <p class="text-start">progress of current print</p>
                        <div class="progress mt-4 glow-button" style="height: 1.5rem;">
                            <div :style="'width: '+printingProgress+'%;'" class="progress-bar progress-bar-striped progress-bar-animated bg-primary" role="progressbar" aria-valuenow="75" aria-valuemin="0" aria-valuemax="100">
                                {{ printingProgress }}%
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="col d-flex flex-column gap-2 align-items-center">

                <p v-if="printerStore.editTicketQueue.length == 0" class="fst-italic text-muted" style="font-size: 1.2rem;">Ticket queue is empty</p>

                <div class="card" style="width: 12rem; height: 9rem;" v-for="t in printerStore.editTicketQueue" :key="t.index" >
                    <img class="card-img-top" :src="t.fileImageAddress" alt="Card image cap">
                    <div style="padding: 0;" class="card-body d-flex gap-2 ">
                        <p class="card-title">{{ t.ticket_name }}</p>
                        <div :style="'width: 1rem; height: 1rem; background-color: ' + ticketStore.convertMaterialToColorUI(t.material) " class="rounded border border-primary"></div>
                    </div>
                </div>

            </div>
        </div>
    </div>
</template>

<script>
import { usePrinterStore } from '@/stores/printers';
import { useTicketsStore } from '@/stores/tickets';
import { ref } from 'vue';

export default {
    name: "PrinterModal",

    setup(){
        const printerStore = usePrinterStore();
        const ticketStore = useTicketsStore();

        let printingProgress = ref(45);

        return {
            printerStore,
            ticketStore,
            printingProgress
        }
    }
}

</script>

<style scoped>

.glow-button {
  --box-shadow-color: rgb(31, 174, 231);
  box-shadow: 0 0 10px var(--box-shadow-color);
}

</style>
<template>
  <div class="d-flex p-4 flex-wrap viewOffset" width="80%">
    <div v-for="p in printers" :key="p.index">
      <PrinterCard :printer="p"></PrinterCard>
    </div>
    <h3 v-if="printers.length == 0" class="m-4 fst-italic text-muted">no printers added</h3>
  </div>
</template>

<script>
import { usePrinterStore } from '@/stores/printers';
import PrinterCard from '../cards/PrinterCard.vue';

export default {
  name: 'PrinterView',
  components:{
    PrinterCard,
  },
  setup(){
    const printerStore = usePrinterStore();
    const printers = printerStore.printers;

    return{
      printerStore,
      printers,
    }
  }
}

</script>

<style scoped>

.viewOffset{
  margin-top: 130px;
}

</style>
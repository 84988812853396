<template>
    <div class="viewOffset">
        <div id="plotElement"></div>
        <div id="subplots"></div>
    </div>
</template>

<script>
import Plotly from 'plotly.js/dist/plotly'
import { onMounted } from 'vue';

export default {
 name: 'StatsView',
 setup(){
    onMounted(()=>{
        var xData = ['Blue', 'Orange',
      'Green', 'Red',
      'Purple', 'grey',
      'pink', 'light blue',
      'light green'];

        function getrandom(num , mul) {
            var value = [ ];
            for ( i = 0; i <= num; i++ ) {
                var rand = Math.random() * mul;
                value.push(rand);
            }
            return value;
        }

        var yData = [
                getrandom(30 ,10),
                getrandom(30, 20),
                getrandom(30, 25),
                getrandom(30, 40),
                getrandom(30, 45),
                getrandom(30, 30),
                getrandom(30, 20),
                getrandom(30, 15),
                getrandom(30, 43),
            ];
        //var colors = ['rgba(93, 164, 214, 0.5)', 'rgba(255, 144, 14, 0.5)', 'rgba(44, 160, 101, 0.5)', 'rgba(255, 65, 54, 0.5)', 'rgba(207, 114, 255, 0.5)', 'rgba(127, 96, 0, 0.5)', 'rgba(255, 140, 184, 0.5)', 'rgba(79, 90, 117, 0.5)', 'rgba(222, 223, 0, 0.5)'];

        var data = [];

        for ( var i = 0; i < xData.length; i ++ ) {
            var result = {
                type: 'box',
                y: yData[i],
                name: xData[i],
                boxpoints: 'all',
                jitter: 0.5,
                whiskerwidth: 0.2,
                fillcolor: 'cls',
                marker: {
                    size: 2
                },
                line: {
                    width: 1
                }
            };
            data.push(result);
        }

        const layout = {
            title: 'Printed materials',
            yaxis: {
                autorange: true,
                showgrid: true,
                zeroline: true,
                dtick: 5,
                gridcolor: 'rgb(255, 255, 255)',
                gridwidth: 1,
                zerolinecolor: 'rgb(255, 255, 255)',
                zerolinewidth: 2
            },
            margin: {
                l: 40,
                r: 30,
                b: 80,
                t: 100
            },
            paper_bgcolor: 'rgb(243, 243, 243)',
            plot_bgcolor: 'rgb(243, 243, 243)',
            showlegend: false
        };

        Plotly.newPlot('plotElement', data, layout);







        //subplots
        var trace1 = {

        x: [1, 2, 3],

        y: [4, 5, 6],

        type: 'scatter'

        };


        var trace2 = {

        x: [20, 30, 40],

        y: [50, 60, 70],

        xaxis: 'x2',

        yaxis: 'y2',

        type: 'scatter'

        };


        var trace3 = {

        x: [300, 400, 500],

        y: [600, 700, 800],

        xaxis: 'x3',

        yaxis: 'y3',

        type: 'scatter'

        };


        var trace4 = {

        x: [4000, 5000, 6000],

        y: [7000, 8000, 9000],

        xaxis: 'x4',

        yaxis: 'y4',

        type: 'scatter'

        };


        var data2 = [trace1, trace2, trace3, trace4];


        var layout2 = {

        grid: {rows: 2, columns: 2, pattern: 'independent'},

        };


        Plotly.newPlot('subplots', data2, layout2);
    });
 },
}
</script>

<style scoped>

.viewOffset{
    margin-top: 130px;
}

</style>
<template>

<div class="mx-5 my-3">
    <b-row>
      <b-col cols="7">
        <form class="text-start ">
          <div class="d-flex">
            <b-col class="d-flex flex-column">
              <label for="uploadFile" class="form-label fs-5">{{ manageStore.lang("dialogs.uploadModal.upload_stl") }}</label>
              <input @change="onFileSelected" style="width: 75%;" ref="fileInput" class="form-control form-control-sm" id="uploadFile" type="file" accept=".stl" />
            </b-col>
          </div>
          <div class="form-group">
            <label for="modelname" class="fs-5">{{ manageStore.lang("dialogs.uploadModal.model_name") }}</label>
            <input v-model="ticketStore.editTicket.ticket_name" type="text" class="form-control" id="modelname" placeholder="Enter Model Name">
          </div>
          <div class="form-group">
            <label class="fs-5">{{ manageStore.lang("dialogs.uploadModal.printing_color") }}</label>
            <select v-model="ticketStore.editTicket.material" class="form-select" aria-label="select printing material">
                <option v-for="m in printingMaterials" :key="m.index" :value="m">{{ m }}</option>
            </select>
          </div>

          <b-row class="d-flex justify-content-center mt-4">
            <b-col>
              <label class="fs-5">{{ manageStore.lang("dialogs.uploadModal.priority") }}</label>
              <select v-model="ticketStore.editTicket.priority" class="form-select" aria-label="select priority">
                  <option v-for="p in priorityLevels" :key="p.index" :value="priorityLevels[p] " >{{ priorityLevelNames[p] }}</option>
              </select>
            </b-col>
            <b-col>
              <label for="delivery-datepicker" class="fs-5">{{ manageStore.lang("dialogs.uploadModal.delivery_date") }}</label>
              <b-form-datepicker v-model="ticketStore.editTicket.deliverydate" id="delivery-datepicker" size="sm"></b-form-datepicker>
            </b-col>
          </b-row>
        </form>
      </b-col>

      <b-col>
        <div v-if="ticketStore.imageGenerated">
          <label  class="fs-5">{{ manageStore.lang("dialogs.uploadModal.ticket_thumbnail") }}</label>
          <canvas id="threeviewport_upload"></canvas>
        </div>
      </b-col>
    </b-row>
  
  <div v-if="!ticketStore.isUploading" class="d-flex justify-content-center m-4 gap-4">
    <button @click="upload()" type="button" :class="'btn btn-lg btn-primary text-white ' + (isValidTicket ? '' : ' disabled')">{{ manageStore.lang("dialogs.uploadModal.send") }}</button>
  </div>
  <div v-else class="progress mt-4 glow-button" style="height: 1.5rem;">
    <div :style="'width: ' + ticketStore.uploadProgress + '%;'" class="progress-bar progress-bar-striped progress-bar-animated bg-primary" role="progressbar" aria-valuenow="75" aria-valuemin="0" aria-valuemax="100">
      {{ ticketStore.uploadProgress }}%
    </div>
  </div>
  <div v-if="ticketStore.uploaded">
    <h5 v-if="ticketStore.uploadSuccess" class="mt-3">Upload successfull - <i class="bi bi-check-circle-fill text-statusPrinted"></i></h5>
    <h5 v-else class="mt-3 text-dismissred">Upload Failed - <i class="bi bi-exclamation-circle-fill text-dismissred"></i></h5>
    <button @click="ticketStore.uploadSuccess = true" type="button" class="btn btn-primary" data-bs-dismiss="modal">close</button>
  </div>

</div>

</template>

<script>

import { useTicketsStore } from "@/stores/tickets.ts";
import { useManageStore } from "@/stores/manage";
import { useAuthStore } from "@/stores/authStore";
import {computed, ref} from 'vue';
import { v4 as uuidv4 } from 'uuid';
import {setViewport, initViewport} from '@/components/ThreeScene/3dloader';

export default{
  name: 'UploadModal',
  setup(){
    const ticketStore = useTicketsStore();
    const manageStore = useManageStore();
    const authStore = useAuthStore();

    const printingMaterials = ticketStore.printingMaterials;
    const priorityLevels = ticketStore.priorityLevels;
    const priorityLevelNames = ticketStore.priorityLevelNames;
    const fileInput = ref();
    const detailPlaceholder = "not_set";

    let stlFiles;

    const ticket_name = computed(()=>{
      const temp = ticketStore.editTicket.model_filepath.split("_");
      return temp[temp.length-1];
    });
  
    function onFileSelected(e) {
      ticketStore.isUploading = false;
      ticketStore.uploadProgress = 0;
      ticketStore.uploaded = false;

      stlFiles = e.target.files || e.dataTransfer.files;
      ticketStore.currentSTLFile = stlFiles[0];

      if (!stlFiles.length){
        return;
      }
      //set model_filepath of the ticket to the one selected
      ticketStore.editTicket.model_filepath = stlFiles[0].name;

      //set ticket_name if not set
      if(ticketStore.editTicket.ticket_name == ""){
        const file_name = stlFiles[0].name;
        ticketStore.editTicket.ticket_name = file_name.substring(0, file_name.length-4);
      }

      renderViewport();
    }

    function renderViewport(){
      //get stl file
      ticketStore.imageGenerated = true;
      const url = URL.createObjectURL(ticketStore.currentSTLFile);

      //render viewport
      setTimeout(()=>{
        initViewport(url, "threeviewport_upload");
      }, 100)
      setTimeout(()=>{
        setViewport(0, 0, 260, 140, "threeviewport_upload");//260, 140
      }, 700);
    }


    const isValidTicket = computed(() =>{      
      return ticketStore.editTicket.ticket_name.length != 0
      && ticketStore.editTicket.material !== ''
      && ticketStore.editTicket.priority !== ''
      && ticketStore.editTicket.deliverydate !== ''
      && ticketStore.editTicket.model_filepath !== '';
    });

    function getSTLFile(){
      ticketStore.downloadSTL(ticketStore.editTicket)
    }

    function upload(){
      ticketStore.isUploading = true;
      fileInput.value.value = null;
      const checkupload = setInterval(()=>{
        const max = 4;
        const min = 1;
        const speed = Math.floor(Math.random() * (max - min + 1)) + min;

        if(ticketStore.uploadProgress < 100){
          ticketStore.uploadProgress += speed;
        }
        else{
          ticketStore.uploadProgress = 100;
          clearInterval(checkupload);
          setTimeout(() => {
            ticketStore.uploaded = true;
          }, 1000);
          sendData()
        }
      },50);
    }

    function sendData(){
      ticketStore.editTicket.client = authStore.preferred_username;

      const canvas = document.getElementById("threeviewport_upload");
      //render canvas
      const dataURL = canvas.toDataURL('image/jpeg');
      ticketStore.renderedCanvasImage = dataURL;
      ticketStore.editTicket.fileImageAddress = dataURL;

      ticketStore.postTicket(Object.assign({}, ticketStore.editTicket));

      ticketStore.editTicket.id = uuidv4();
    }

    return{
      printingMaterials,
      priorityLevels,
      priorityLevelNames,
      isValidTicket,
      ticketStore,
      manageStore,
      sendData,
      onFileSelected,
      fileInput,
      ticket_name,
      detailPlaceholder,
      getSTLFile,
      upload
    }
  }
}
</script>

<style scoped>

  .form-group{
    margin-top: 40px;
  }

  .modal-bg{
    position: fixed;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;

    background-color: rgba(0, 0, 0, 0.5);

    display: flex;
    justify-content: center;
    align-items: center;
  }
  .disabledForm{
    background-color: #e9ecef;
  }

</style>
import { defineStore} from "pinia";
// @ts-ignore
import VueJWTDecode from 'vue-jwt-decode';
import Keycloak from "keycloak-js";

export const useAuthStore = defineStore("authStore", {
    state: () =>{
        return {
            //user info
            given_name: String,
            family_name: String,
            email: String,
            email_verified: String,
            preferred_username: String,

            keycloak: new Keycloak(
                {
                    url: 'https://keycloak.wmaut.de',
                    realm: 'application',
                    clientId: 'printer-system',
                }
            ),
        };
    },
    actions: {
        decodeJWT(token:String){
            const decoded_token = VueJWTDecode.decode(token);

            this.given_name = decoded_token.given_name;
            this.family_name = decoded_token.family_name;
            this.email = decoded_token.email;
            this.email_verified = decoded_token.email_verified;
            this.preferred_username = decoded_token.preferred_username;
        },
        logout(){
            this.keycloak.logout();
        }
    },

});
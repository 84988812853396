<template>
    <nav v-if="manageStore.showNavbar" class="navbar navbar-expand-lg navbar-dark fixed-top shadow-sm">
        <div class="container-fluid">
            <RouterLink class="navbar-brand" to="/">
                <div class="shadow p-3 rounded logo-border">
                    <img width="250PX" src="../assets/images/wzl_navbar_logo.png" alt="">
                </div>
            </RouterLink>
            <button class="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarNav" aria-controls="navbarNav" aria-expanded="false" aria-label="Toggle navigation">
            <span class="navbar-toggler-icon"></span>
            </button>
            <div class="collapse navbar-collapse" id="navbarNav">
            <ul class="navbar-nav">
                <li class="nav-item mx-4">
                    <button type="button" class="btn btn-primary text-white" data-bs-toggle="modal" data-bs-target="#uploadModal" @click="initTicket">
                        <i class="bi bi-file-plus"></i> {{ manageStore.lang("navbar.newticket") }}
                    </button>
                </li>
                <div class="vr fw-bold"></div>
                <li class="nav-item">
                    <RouterLink :class="'nav-link ' + (currentTab == '0' ? 'active' : '')" aria-current="page" to="/" @click="changeTab(0)">{{ manageStore.lang("navbar.ticket_overview") }}</RouterLink>
                </li>
                <li class="nav-item">
                <RouterLink :class="'nav-link ' + (currentTab == '1' ? 'active' : '')" to="/printer" @click="changeTab(1)">{{ manageStore.lang("navbar.printer_overview") }}</RouterLink>
                </li>
                <li class="nav-item  me-4">
                <RouterLink :class="'nav-link ' + (currentTab == '2' ? 'active' : '')" to="/stats" @click="changeTab(2)">{{ manageStore.lang("navbar.stats_overview") }}</RouterLink>
                </li>
                <div class="vr"></div>
                <li class="nav-item ms-4 me-3 gap-2">
                    <label class="fs-4 text-white">{{ manageStore.lang("navbar.filter") }}</label>
                    <select v-model="manageStore.selectedFilter" class="form-select" aria-label="select filter">
                        <option v-for="m in manageStore.filterTypes" :key="m.index" :value="m">{{ m }}</option>
                    </select>
                </li>
                <!-- <li class="nav-item">
                <a class="nav-link disabled" href="#" tabindex="-1" aria-disabled="true">Disabled</a>
                </li> -->
            </ul>

            <div class="d-flex flex-column gap-2">
                <select class="form-select" v-model="manageStore.localeLang" name="lang" id="lang">
                    <option value="en">en</option>
                    <option value="de">de</option>
                </select>
            </div>
            <div class="d-flex align-items-center flex-column gap-2 ms-auto">
                <img class="rounded-circle" :src="manageStore.baseUserImageUrl + authStore.preferred_username" width="60px" alt="">
                <div>
                    <button @click="authStore.logout()" class="btn btn-primary text-white">{{ manageStore.lang("navbar.logout") }}</button>
                    <button class="btn text-white" data-bs-toggle="modal" data-bs-target="#profileModal"><i class="bi bi-gear fs-5"></i></button>
                </div>
            </div>
            </div>
        </div>
    </nav>
</template>

<script>
import { RouterLink } from 'vue-router';
import {ref} from 'vue';
import { PrintingTicket } from '../other/DataStructs.ts'
import { useTicketsStore } from "@/stores/tickets";
import { useManageStore } from '@/stores/manage';
import { useAuthStore } from '@/stores/authStore';

export default {
  name: 'NavBar',
  props:{
    show: Boolean,
  },
  components:{
    RouterLink
  },
  setup(){
    const ticketStore = useTicketsStore();
    const manageStore = useManageStore();
    const authStore = useAuthStore();
    let currentTab = ref(0);

    function changeTab(num){
        currentTab.value = num;
    }
    initTicket();


    function initTicket(){
        ticketStore.editTicket = new PrintingTicket();
        ticketStore.editTicket.ticket_name = "";
        ticketStore.editTicket.priority = "";
        ticketStore.editTicket.material = "";
        ticketStore.editTicket.deliverydate = "";
        ticketStore.editTicket.model_filepath = "";
        ticketStore.editTicket.status = 0;
        ticketStore.imageGenerated = false;
        ticketStore.isUploading = false;
        ticketStore.uploadProgress = 0;
        ticketStore.uploaded = false;
    }

    return{
        currentTab,
        changeTab,
        initTicket,
        ticketStore,
        manageStore,
        authStore
    }
  }
}

</script>

<style scoped>

nav {
  background: linear-gradient(
    to bottom,
    #00549f,
    #79b2eb
  );
}

.nav-item{
    display: flex;
    align-items: center;
    justify-content: center;
}
.logo-border{
    border-color: var(--bs-primary3);
    border-style: solid;
    border-width: 3px;
}

</style>
<template>
    <div class="mx-auto" style="width: 50%;">
        <b-row class="mt-4">
            <b-col>
                <img class="rounded-circle" width="100px" :src="manageStore.userAssign == '' ? manageStore.baseUserImageUrl+'U' : manageStore.baseUserImageUrl+manageStore.userAssign" alt="">
            </b-col>
            <b-col class="d-flex flex-column mt-4">
                <button  data-bs-dismiss="modal" class="btn btn-primary text-white" @click="assignTicket(authStore.preferred_username)">{{ manageStore.lang("dialogs.assigneeModal.assign_to_me") }}</button>
                <input v-model="manageStore.userAssign" type="text" class="form-control" id="profilename" placeholder="Enter Model Name">
            </b-col>
        </b-row>

        <div class="d-flex justify-content-center m-4 gap-4">
            <button @click="assignTicket(manageStore.userAssign)" type="button" class="btn btn-lg btn-primary text-white" data-bs-dismiss="modal">{{ manageStore.lang("dialogs.assigneeModal.save") }}</button>
        </div>
    </div>
</template>

<script>

import { useManageStore } from '@/stores/manage';
import { useTicketsStore } from '@/stores/tickets';
import { useAuthStore } from '@/stores/authStore';

export default {
    name: "AssigneeModal",
    setup(){
        const manageStore = useManageStore();
        const ticketStore = useTicketsStore();
        const authStore = useAuthStore();

        /**
         * 
         * @param assignee {string} Is the User responsible for printing the model
         */
        function assignTicket(assignee){
            const ticket = ticketStore.getTicket(ticketStore.assigningTicket);

            manageStore.sendNotification("assigned", ticket.client, assignee, ticket.ticket_name);
            
            if(assignee != ""){
                ticket.assignee = assignee;
                if(ticket.status == 0){
                    ticket.status = 1;
                }
            }
            else{
                ticket.assignee = "unassigned"
            }
            ticketStore.putTicket(ticket);
        }

        return {
            manageStore,
            ticketStore,
            assignTicket,
            authStore
        }
    }
}

</script>

<style scoped>

</style>
import { defineStore} from "pinia";
import {PrintingTicket} from '../other/DataStructs'
import { ref } from "vue";
import axios from "axios";
import { usePrinterStore } from "./printers";

export const useTicketsStore = defineStore("ticketStore", {
    state: () =>{
        return {
            //tickets
            models: ref([] as PrintingTicket[]),
            printingMaterials:[
                "any",
                "white",
                "black",
                "blue",
                "red",
                "orange"
            ],
            printingColorsUI:[
                "#ffffff",
                "#ffffff",
                "#000000",
                "#4d76ff",
                "#f23633",
                "#f7a839",
            ],
            priorityLevels:[0, 1, 2],
            priorityLevelNames:["low", "medium", "high"],
            states: ["not assigned", "in progress", "printed"],
            stateColors: ["statusNot", "statusProgress", "statusPrinted"],

            editTicket: new PrintingTicket("", "", "", "", 0, "", "", "", ""),
            imageGenerated: false,
            sendWithBar: false,
            isUploading: ref(false),
            uploadProgress: ref(0),
            uploaded: ref(false),
            uploadSuccess: true,
            renderedCanvasImage: "",

            editFileInput: ref(),
            currentSTLFile: "",
            assigningTicket: "",
        };
    },
    actions: {
        postTicket(newTicket: PrintingTicket){
            //ui update
            newTicket.printer = "test";/*TEMP */
            this.models.push(newTicket)

            const formData = new FormData();
            formData.append('stl_file', this.currentSTLFile);
            formData.append('jpeg_file', this.createJPEG(this.renderedCanvasImage), newTicket.ticket_name+".jpg");
            formData.append('client_name', newTicket.client);
            formData.append('asignee', newTicket.assignee);
            formData.append('printer', newTicket.printer);
            formData.append('ticket_name', newTicket.ticket_name);
            formData.append('image_string', newTicket.fileImageAddress);
            formData.append('delivery_date', newTicket.deliverydate);
            formData.append('priority', this.priorityLevelNames[newTicket.priority]);
            formData.append('status', this.states[newTicket.status]);
            formData.append('color', newTicket.material);

            axios.post(`${process.env.VUE_APP_API_URL}/database/tickets/`,formData)
            .then((response) => {
                console.log(response);
            }, (error) => {
                console.log(error);
                this.uploadSuccess = false;
            });
        },
        createJPEG(canvasImage:string){
            let blob:any = "";
            // atob to base64_decode the data-URI
            const image_data = atob(canvasImage.split(',')[1]);
            // Use typed arrays to convert the binary data to a Blob
            const arraybuffer = new ArrayBuffer(image_data.length);
            const view = new Uint8Array(arraybuffer);
            for (let i=0; i<image_data.length; i++) {
                view[i] = image_data.charCodeAt(i) & 0xff;
            }
            try {
                // This is the recommended method:
                blob = new Blob([arraybuffer], {type: 'img/jpeg'});
            }
            catch(e){
                console.log(e);
            }
            return blob;
        },
        putTicket(ticket: PrintingTicket){
            // replace edited Ticket
            let index = 0;
            for (let i = 0; i < this.models.length; i++) {   
                if(this.models[i].id === ticket.id){
                        index = i;
                        break;
                }
            }
            //ui update
            ticket.printer = "test"; /*TEMP */
            this.models[index] = Object.assign({}, ticket);

            axios.put(`${process.env.VUE_APP_API_URL}/database/ticketsdatabase/${ticket.id}`,
            {
                id: ticket.id,
                client_name: ticket.client,
                printer: ticket.printer,
                asignee: ticket.assignee != undefined ? ticket.assignee : "",
                ticket_name: ticket.ticket_name,
                image_string: ticket.fileImageAddress,
                model_filepath: ticket.model_filepath,
                delivery_date: ticket.deliverydate,
                priority: this.priorityLevelNames[ticket.priority],
                status: this.states[ticket.status],
                color: ticket.material,
                category_id: 0,
                category_name: "none"
            })
            .then((response) => {
                console.log(response);
            }, (error) => {
                console.log(error);
                this.uploadSuccess = false;
            });
        },
        removeTicket(ticket: PrintingTicket){
            //ui update
            let index = 0;
            for (let i = 0; i < this.models.length; i++) {
               if(this.models[i].id == ticket.id)
               {
                    index = i;
                    break;
               }
            }
            this.models.splice(index, 1);
            
            axios.delete(`${process.env.VUE_APP_API_URL}/database/ticketsdatabase/${ticket.id}`)
            .then((response) => {
                console.log(response);
            }, (error) => {
                console.log(error);
            });

            //this.fetchAllTickets();
        },
        async fetchAllTickets(){
            const responseObject = await axios.get(`${process.env.VUE_APP_API_URL}/database/tickets/`);
            const tickets = responseObject.data.items;
            const fetchedTickets: PrintingTicket[] = [];
            
            for (let i = 0; i < tickets.length; i++) { 
                const ticket = new PrintingTicket(
                    tickets[i].client_name,
                    tickets[i].asignee,
                    tickets[i].printer,
                    tickets[i].ticket_name,
                    this.convertPriority(tickets[i].priority),
                    tickets[i].delivery_date,
                    tickets[i].color,
                    tickets[i].image_string,
                    tickets[i].model_filepath
                );
                ticket.id = tickets[i].id;
                ticket.status = this.convertStatus(tickets[i].status);
                fetchedTickets.push(ticket);
            }

            //update models array
            this.models.length = 0;
            for(const f of fetchedTickets){
                this.models.push(f);
            }

            //clear all printer ticketqueues
            const printerStore = usePrinterStore();
            for (let i = 0; i < printerStore.printers.length; i++) {
                const printer = printerStore.printers[i];
                printer.ticketQueue.length = 0;
            }

            //assign tickets to printers based on color
            if(this.models.length != 0){
                for (let i = 0; i < this.models.length; i++) {
                    const model = this.models[i];

                    for (let j = 0; j < printerStore.printers.length; j++) {
                        const printer = printerStore.printers[j];

                        if(model.material == printer.colorInUse){
                            printer.ticketQueue.push(model.id);
                        }
                    }
                }
                
            }
        },
        async downloadSTL(ticket: PrintingTicket){
            window.open(`${process.env.VUE_APP_API_URL}/database/tickets/${ticket.id}/stl`);
        },
        async getJPEG(ticket: PrintingTicket){
            const base64:any = await axios.get(`${process.env.VUE_APP_API_URL}/database/tickets/${ticket.id}/jpeg`);
            console.log(base64);

            const jpegBlob = new Blob([base64], { type: 'image/jpeg' });
                this.blobToDataURL(jpegBlob, function(dataUrl:any) {
                console.log("dataurl",dataUrl);  // Outputs: "data:image/jpeg;base64,/9j/4AAQSkZJRgABAQAAAQABAAD..."
            });
            
            return jpegBlob;
        },
        blobToDataURL(blob:any, callback:any) {
            const reader = new FileReader();
            
            // Set up the onload event to get the Data URL when the Blob is read
            reader.onloadend = function() {
              // The result is the Base64-encoded Data URL
              callback(reader.result);
            };
          
            // Read the Blob as a Data URL
            reader.readAsDataURL(blob);
          },
        convertPriority(priorityName: string): number{
            for (let j:number = 0; j < this.priorityLevelNames.length; j++) {
                if (priorityName == this.priorityLevelNames[j]){
                    return j;
                }
            }
            return 0;
        },
        convertStatus(status: string): number{
            for (let i = 0; i < this.states.length; i++) {
                const state = this.states[i];
                if(status == state){
                    return i;
                }
            }
            return 0;
        },
        getTicket(searchId: string){
            for(const ticket of this.models){
                if(ticket.id == searchId){
                    return ticket;
                }
            }
            //throw "ticket with this id not found";
        },
        convertMaterialToColorUI(material: string): string{
            let index = 0;
            for (let i = 0; i < this.printingMaterials.length; i++) {
                const mat = this.printingMaterials[i];
                if(mat == material){
                    index = i;
                    break;
                }
            }
            return this.printingColorsUI[index];
        }
    },

});
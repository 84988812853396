import * as THREE from 'three';
//import { GLTFLoader } from 'three/addons/loaders/GLTFLoader.js';
import { OrbitControls } from 'three/addons/controls/OrbitControls.js';
import { STLLoader } from 'three/examples/jsm/loaders/STLLoader'

let renderer;

//camera settings
const fov = 60;
const near = 0.1;
const far = 1000;
const camPos = [0.5, 0.6, 0.3];

export function setViewport(x, y, width, height, id){
    renderer.setSize(width, height);
    const viewport = document.getElementById(id);
    viewport.style.left = x + "%";
    viewport.style.top = y + "%";
}

export function initViewport(modelPath, id){
    setTimeout(()=>{
        //initialization

        const scene = new THREE.Scene();
        const threeviewport = document.getElementById(id);
        renderer = new THREE.WebGLRenderer({
            preserveDrawingBuffer: true,
            antialias: true,
            canvas: threeviewport,
        });
        renderer.setSize( threeviewport.clientWidth, threeviewport.clientHeight );
        renderer.setAnimationLoop( animate );
        const camera = new THREE.PerspectiveCamera( fov, threeviewport.clientWidth / threeviewport.clientHeight, near, far );

        //lighting
        scene.background = new THREE.Color( 0x547080 );
        let light = new THREE.AmbientLight(0xffffff);
        const directionalLight = new THREE.DirectionalLight(0xffffff, 5)
        scene.add(light);
        scene.add(directionalLight)

        const controls = new OrbitControls( camera, renderer.domElement );
        controls.update();
        
        const size = 10;
        const divisions = 10;
        const gridHelper = new THREE.GridHelper( size, divisions );
        scene.add( gridHelper );
        
        
        //scene content-------------------------------------
        const material = new THREE.MeshStandardMaterial({
            color:  0x194D33 ,
            roughness: 0.5,
        })

        const loader = new STLLoader()
        loader.load(modelPath, (geometry) => {
                const mesh = new THREE.Mesh(geometry, material);
                let scale = 0.01;
                mesh.scale.set(scale,scale,scale);
                mesh.rotateX(-Math.PI/2);
                scene.add(mesh);
            }
        )

        camera.position.set(camPos[0], camPos[1], camPos[2]);

        //animation loop
        function animate() {
            controls.update();
            renderer.render( scene, camera );
        }

        document.addEventListener( 'drop', function ( event ) {
            console.log(event);
        });
    },500)
}
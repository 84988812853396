<template>
    <div class="d-flex flex-column justify-content-center align-items-center">
        <img class="mt-4" width="500px" src="../../assets/images/wzl_navbar_logo.png" alt="WZL logo">
        <h1 style="font-weight: bold;">Authentication Failed!</h1>
        <p class="mt-4" style="font-style: italic;">please contact a systems administrator or IT Support</p>
        <p class="mt-4">{{ manageStore.errorCode }}</p>
    </div>
</template>

<script>
import { useManageStore } from '@/stores/manage';


export default {
    name: "AuthFailed",
    setup(){

        const manageStore = useManageStore();

        return {
            manageStore
        }
    }
}

</script>

<style scoped>

</style>